import { Link } from "gatsby";
import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Arrow } from "@/components/arrow";
import { Layout } from "@/components/layout";
import { GatsbySeo, JsonLd } from "gatsby-plugin-next-seo";

const AboutUsPage = () => {
  return (
    <>
      <GatsbySeo
        title="About Us | Tectra Technologies"
        description="We, at Tectra Technologies, collaborate with entrepreneurs and business leaders to bring transformative ideas to reality using different technologies. As a pioneer in the software development market, we cater to the diverse needs of traditional as well as digital businesses while emphasizing performance, accessibility, reliability, and quality. All our specialized services like web development, mobile application development, UI/UX design, cloud computing, DevOps, Quality Assurance, etc. are customized as per varied needs and specifications of business verticals."
        canonical="https://www.tectratechnologies.com/about-us/"
        openGraph={{
          locale: "en_US",
          type: "article",
          url: "https://www.tectratechnologies.com/about-us/",
          title: "About Us - Tectra Technologies",
          description:
            "We, at Tectra Technologies, collaborate with entrepreneurs and business leaders to bring transformative ideas to reality using different technologies. As a pioneer in the software development market, we cater to the diverse needs of traditional as well as digital businesses while emphasizing performance, accessibility, reliability, and quality. All our specialized services like web development, mobile application development, UI/UX design, cloud computing, DevOps, Quality Assurance, etc. are customized as per varied needs and specifications of business verticals.",
          site_name: "Tectra Technologies",
        }}
        twitter={{
          handle: "@handle",
          site: "@TectraL",
          cardType: "summary_large_image",
        }}
      />
      <JsonLd
        json={{
          "@context": "https://schema.org",
          "@type": "WebSite",
          "@graph": [
            {
              "@type": "WebSite",
              "@id": "https://www.tectratechnologies.com/#website",
              url: "https://www.tectratechnologies.com/",
              name: "Tectra Technologies",
              description:
                "Web &amp; Mobile App, Cloud, UI/UX, Devops, Tax Services, Digital Marketing",
              potentialAction: [
                {
                  "@type": "SearchAction",
                  target: {
                    "@type": "EntryPoint",
                    urlTemplate:
                      "https://www.tectratechnologies.com/?s={search_term_string}",
                  },
                  "query-input": "required name=search_term_string",
                },
              ],
              inLanguage: "en-US",
            },
            {
              "@type": "WebPage",
              "@id": "https://www.tectratechnologies.com/about-us/",
              url: "https://www.tectratechnologies.com/about-us/",
              name: "About Us - Tectra Technologies",
              isPartOf: {
                "@id": "https://www.tectratechnologies.com/#website",
              },
              datePublished: "2019-06-18T09:29:45+00:00",
              dateModified: "2022-07-28T06:10:07+00:00",
              description:
                "We, at Tectra Technologies, collaborate with entrepreneurs and business leaders to bring transformative ideas to reality using different technologies. As a pioneer in the software development market, we cater to the diverse needs of traditional as well as digital businesses while emphasizing performance, accessibility, reliability, and quality. All our specialized services like web development, mobile application development, UI/UX design, cloud computing, DevOps, Quality Assurance, etc. are customized as per varied needs and specifications of business verticals.",
              breadcrumb: {
                "@id":
                  "https://www.tectratechnologies.com/contact-us/#breadcrumb",
              },
              inLanguage: "en-US",
              potentialAction: [
                {
                  "@type": "ReadAction",
                  target: ["https://www.tectratechnologies.com/about-us/"],
                },
              ],
            },
            {
              "@type": "BreadcrumbList",
              "@id":
                "https://www.tectratechnologies.com/contact-us/#breadcrumb",
              itemListElement: [
                {
                  "@type": "ListItem",
                  position: 1,
                  name: "Home",
                  item: "https://www.tectratechnologies.com/",
                },
                { "@type": "ListItem", position: 2, name: "About Us" },
              ],
            },
          ],
        }}
      />
      <Layout>
        <div className="bg-white py-20">
          <div className="w-full max-w-7xl mx-auto px-5 lg:px-8">
            <div className="text-left my-8 xl:my-24 xl:w-3/5">
              <div className="py-24">
                <h1 className="text-black text-4xl font-poppins font-semibold mb-4 capitalize">
                  Empowering And Transforming Business
                </h1>
                <div className="relative flex overflow-hidden justify-start items-center text-center">
                  <Link
                    to="/contact"
                    className="flex items-center text-xl capitalize hover:text-black"
                    activeClassName="border-b-2 border-black"
                  >
                    Talk to Us
                    <Arrow color="black" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-transparent mt-10 lg:mt-16">
          <div className="w-full">
            <StaticImage
              src="../images/company-hero.png"
              quality={95}
              height={700}
              className="w-full h-60 md:h-92 lg:h-auto"
              formats={["auto", "png"]}
              alt="Tectra Technologies"
            />
          </div>
        </div>

        <div className="bg-black ">
          <div className="max-w-7xl mx-auto px-5 lg:px-8">
            <div className="pt-10 lg:pt-20">
              <p className="text-lg  text-white font-poppins font-thin leading-8">
                With headquarters in India and offices in the United States and
                Malaysia, Tectra Technologies is expanding globally on the
                strength of high-quality tech solutions and services. From
                Startups to Enterprises to Sole Proprietors, we work with all
                sized companies and businesses. We pursue our clients’ projects
                as our own and work with the same passion and determination to
                offer unmatched and unforgettable customer experiences.
              </p>
              <p className="text-lg text-white font-poppins font-thin mt-4 lg:mt-6 mx-auto leading-8">
                As a pioneer in the software development market, we cater to the
                diverse needs of traditional as well as digital businesses while
                emphasizing performance, accessibility, reliability, and
                quality. All our specialized services like web development,
                mobile application development, UI/UX design, cloud computing,
                DevOps, Quality Assurance, etc. are customized as per varied
                needs and specifications of business verticals
              </p>
            </div>
            <div className="mt-6 lg:mt-12 pb-10 lg:pb-20">
              <div className="grid grid-cols-2 md:grid-cols-4 gap-0.5 items-center">
                <div className="col-span-1 flex justify-center px-8 py-8">
                  <div className="text-center">
                    <StaticImage
                      src="../images/iso-27001.png"
                      alt="ISO 270001:2013"
                      height={100}
                      className="w-full"
                      quality={95}
                      formats={["auto", "png"]}
                    />
                  </div>
                </div>
                <div className="col-span-1 flex justify-center px-8 py-8">
                  <div className="text-center">
                    <StaticImage
                      src="../images/iso-9001-2015.png"
                      alt="ISO 9001:2015"
                      height={100}
                      quality={95}
                      className="w-full"
                      formats={["auto", "png"]}
                    />
                  </div>
                </div>
                <div className="col-span-1 flex justify-center px-8 py-8">
                  <div className="text-center">
                    <StaticImage
                      src="../images/iaf.png"
                      alt=""
                      height={150}
                      quality={95}
                      className="w-28 lg:w-full"
                      formats={["auto", "png"]}
                    />
                  </div>
                </div>
                <div className="col-span-1 flex justify-center px-8 py-8">
                  <div className="text-center">
                    <StaticImage
                      src="../images/snowflake.png"
                      alt=""
                      height={100}
                      quality={95}
                      className="w-full"
                      formats={["auto", "png"]}
                    />
                  </div>
                </div>
                <div className="col-span-1 flex justify-center px-8 py-8">
                  <div className="text-center">
                    <StaticImage
                      src="../images/web_development_expert_usa.png"
                      alt=""
                      height={100}
                      quality={95}
                      className="w-full"
                      formats={["auto", "png"]}
                    />
                  </div>
                </div>
                <div className="col-span-1 flex justify-center px-8 py-8">
                  <div className="text-center">
                    <StaticImage
                      src="../images/mobile_app_expert_usa.png"
                      alt=""
                      height={100}
                      quality={95}
                      className="w-full"
                      formats={["auto", "png"]}
                    />
                  </div>
                </div>
                <div className="col-span-2 sm:col-span-1 flex justify-center px-8 py-8">
                  <div className="text-center">
                    <StaticImage
                      src="../images/clutch_logo.png"
                      alt=""
                      height={100}
                      quality={95}
                      className="w-full"
                      formats={["auto", "png"]}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default AboutUsPage;
